<template>
	<div
		class="full-height flex-column"
	>
		<Search

			:search="item_search"
			:option="search_option"

			@reset="reset"
			@click="getSearch"
			@toExcel="toExcel"
			class="mt-10"
		>

			<select
				v-model="item_search.carNum"
				class="pa-5-10 "
				@change="getData"
				slot="first"
			>
				<option value="">기프트콘 종류</option>
				<option
					v-for="(mobile, m_index) in items_list"
					:key="'mobile_' + m_index"
					class="btn btn-s mr-5 mb-5"
					:value="mobile.goods_id"
				>{{ mobile.goods_nm }}</option>
			</select>

			<button
				slot="add"
				class="btn-white pa-5-10  ml-10"
				@click="setMonth(0)"
			>지난달</button>
			<button
				slot="add"
				class="btn-white pa-5-10  ml-10"
				@click="setMonth(1)"
			>2달전</button>
		</Search>

		<div
			class="mt-10 pa-10 bg-white full-height"
		>

			<div
				v-if="false"
			>
				<button
					v-for="(mobile, m_index) in items_giftcon"
					:key="'mobile_b_' + m_index"

					class="btn pa-5-10 mr-5 size-px-12"
					:class="{'btn-primary': mobile.goods_id == item_search.carNum}"

					@click="item_search.carNum = mobile.goods_id; getData()"
				>{{ mobile.goods_nm}}</button>
			</div>
			<div
				v-if="item_list.length > 0"
			>
				<table class="mt-10 table table-even">
					<colgroup>
						<col
							width="80px"
						/>
					</colgroup>
					<thead>
					<tr>
						<th>일자</th>
						<th>기프트콘</th>
						<th>전체 금액</th>
						<th>전체 수량</th>
						<th>승인 금액</th>
						<th>승인 수량</th>
						<th>취소 금액</th>
						<th>취소 수량</th>
					</tr>
					</thead>
					<tbody>
					<tr
						v-for="item in item_list"
						:key="item.uid"
						:class="{ 'bg-select': item.uid == item_new.uid }"
					>
						<td>{{ item.ym }}</td>
						<td>{{ item.goods_nm }}</td>
						<td>{{ item.tAmt | makeComma }}</td>
						<td>{{ item.tCnt | makeComma }}</td>
						<td>{{ item.tcAmt | makeComma }}</td>
						<td>{{ item.tcCnt | makeComma }}</td>
						<td>{{ item.tccAmt | makeComma }}</td>
						<td>{{ item.tccCnt | makeComma }}</td>
					</tr>
					</tbody>
				</table>
			</div>
			<Empty
				v-else
			></Empty>
		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"

			@close="is_excel = false"
		></Excel>

	</div>
</template>

<script>

import Excel from "@/components/Excel";
import Search from "@/view/Layout/Search";
import Empty from "@/view/Layout/Empty";

export default {
	name: 'StatisticsMobile'
	, components: { Empty, Search, Excel}
	, props: ['user', 'codes']
	,data: function (){
		return {
			program: {
				name: '모바일 기프트콘 통계'
				,top: true
				,title: true
			}
			,item_search: this.$storage.init({
				sDate: this.$date.getMonthlyDate('', '-').start
				, eDate: this.$date.getToday('-')
				, carNum: 'G00000004203'
			})
			,search_option:{
				is_excel: true
				,sDate: true
				,eDate: true
				, not_search: true
				, search_limit: 90
				, is_reset: true
			}
			,items: []
			,item_new: {
				ATOKEN: this.TOKEN
			}
			,agency_list: [

			]
			,is_excel: false
			,excel_data: {
				name: '모바일 기프트콘 통계'
				,header: [
					{ key: 0, name: '일자', column: 'ym'}
					,{ key: 0, name: '기프트콘', column: 'goods_nm'}
					,{ key: 0, name: '금액', column: 'totAmt'}
					,{ key: 0, name: '전체 금액', column: 'tAmt'}
					,{ key: 0, name: '전체 수량', column: 'tCnt'}
					,{ key: 0, name: '승인 금액', column: 'tcAmt'}
					,{ key: 0, name: '승인 수량', column: 'tcCnt'}
					,{ key: 0, name: '취소 금액', column: 'tccAmt'}
					,{ key: 0, name: '취소 수량', column: 'tccCnt'}
				]
				,content: null
			}
			,is_item : false
			, items_agency: []
			, item: {}
			, item_mobile: {}
			, is_on_detail: false
			, items_giftcon: []
		}
	}
	,computed: {
		item_list: function (){
			return this.items.filter((item) => {

				switch (item.member_status){
					case '1':
						item.member_status_name = '사용'
						break;
					default:
						item.member_status_name = '미사용'
						break;
				}

				this.items_giftcon.filter( (goods) => {
					if(goods.goods_id == item.cardNum){
						item.goods_nm = goods.goods_nm
					}
				})
				return item
			})
		}
		,is_agency: function(){
			if(this.user.admin_type == 'agency'){
				return true
			}else{
				return false
			}
		}
		,select_option_agency: function(){
			let list = []
			this.agency_list.filter(function(item){

				list.push({
					name: item.agency_name
					,column: item.uid
				})
			})

			return list
		}
		, items_list: function(){
			let t = this.items_giftcon

			const result1 = t.sort((a, b) => a.goods_nm.toLowerCase() < b.goods_nm.toLowerCase() ? -1 : 1);

			return result1
		}
	}
	,methods: {
		getData: async function(){

			this.is_item = false
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getOrderGiftconStatistics'
					,data: this.item_search
					, version: 'v2'
				})

				if(result.success){
					this.items = result.data.result
					this.$set(this.item_search, 'total_count', result.data.tCnt)
					this.search_option.tCnt = result.data.tCnt
					this.search_option.cnt = result.data.cnt
					this.$storage.setQuery(this.item_search)
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
				this.is_excel = false
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, reset: function() {
			this.item_search.sDate = this.$date.getMonthlyDate('', '-').start
			this.item_search.eDate = this.$date.getToday('-')

			this.getData()
		}
		, toExcel: function(){

			this.is_excel = true
			this.excel_data.content = this.items_giftcon
		}
		, setMonth: function(input){
			let now = this.$date.getToday('-')
			let d = this.$date.getPrevMonth(now, input).fullDate
			let t = this.$date.getMonthlyDate(d, '-')
			this.item_search.sDate = t.start
			this.item_search.eDate = t.end
			this.getData()
		}
		, getList: async function(){

			this.is_item = false
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getGiftconList'
					, version: 'v2'
				})

				if(result.success){
					this.items_giftcon = result.data
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, do: async function(){
			await this.getList()
			await this.getData()
		}

		, getSearch: function(){
			this.do()
		}
	}
	, created(){
		this.$emit('onLoad', this.program)
		this.do()
	}
	,watch: {
	}
}
</script>